import React, { useEffect } from "react";
import Blog from "./Blog";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";


export default function BlogComponents() {
  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
  }, []);

  return (
    <div>
      <Navbar   />
      <Blog />

      <Footer />
    </div>
  );
}
