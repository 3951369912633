import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Footer from "../Home/homeSec9/Footer";
import Navbar from "../navbar/Navbar";
import HomeConsultant from "../Home/homeSec7/HomeConsultant";
import HomeSubscribe from "../UnusedComponents/homeSec8/HomeSubscribe";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function ReviewPage() {
  const [reviewHeroData, setReviewHeroData] = useState(null);
  const [reviewCardData, setReviewCardData] = useState(null);
  const [filteredReviewsData, setFilteredReviewsData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const reviewsPerPage = 3;
  const reviewsPerPageLink = 10;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Fetch review hero data
    client
      .fetch(`*[_type == 'review'][0].reviewHeroData`)
      .then((data) => {
        setReviewHeroData(data);
        setFilterCriteria(data.btnLatest.btnLatestText);
      })
      .catch((error) =>
        console.error("Error fetching review hero data:", error)
      );

    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchPageData();

    // Fetch review card data
    client
      .fetch(`*[_type == 'review'][0].reviewCardData`)
      .then((data) => {
        setReviewCardData(data);
        if (!filterCriteria)
          setFilteredReviewsData(
            data.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime))
          );
      })
      .catch((error) =>
        console.error("Error fetching review card data:", error)
      );
  }, []);

  if (!reviewHeroData || !reviewCardData) {
    return <div></div>;
  }

  const filterReviewsByTitle = (title) => {
    setFilteredReviewsData(
      reviewCardData.filter((review) => review.filterText === title)
    );
    setFilterCriteria(title);
    setStartIndex(0);
  };

  const filterReviewByTime = (reviews, title) => {
    const reviewsIST = reviews.map((review) => {
      return review;
    });
    setFilteredReviewsData(
      reviewsIST.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime))
    );
    setFilterCriteria(title);
  };

  const isActive = (title) => {
    return title === filterCriteria;
  };

  const handleNextPage = () => {
    const nextIndex = startIndex + reviewsPerPage;
    if (nextIndex >= filteredReviewsData.length) {
      // Scroll to the top
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // Show alert message after scrolling
      setTimeout(() => {
        setAlertMessage("No more reviews to show.");
        // Hide alert after 3 seconds
        setTimeout(() => setAlertMessage(""), 1000);

      }, 500); // Delay for the scroll animation duration
    } else {
      setStartIndex(nextIndex);
      // Scroll to the review-box section
      const reviewBoxSection = document.getElementById("review-box");
      if (reviewBoxSection) {
        reviewBoxSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  function convertDateToPrintable(date) {
    let dateArray = new Date(date).toString().split(" ");
    let printableDate =
      " " + dateArray[1] + " " + dateArray[3];
    return printableDate;
  }

  // const getRandomColor = (colors) => {
  //   const randomIndex = Math.floor(Math.random() * colors.length);
  //   return colors[randomIndex];
  // };

  // const customColors = ["#FF8484", "#2351F5", "#FF7C32", "#23A54F", "#9921C3"]; // Custom colors array

  // // Usage example
  // const randomColor = getRandomColor(customColors);

  return (
    <>
      <Navbar />
      <div className="reviews-container w-full">
        {alertMessage && (
          <div className="alert-message fixed top-[0vh] left-10 lg:left-[40%] poppins transform-translate-x-1/2 bg-gradient-to-r from-yellow-500 to-yellow-300 text-xl lg:text-2xl text-[#ffffff] py-4 px-8 rounded">
            {alertMessage}
          </div>
        )}
        <div className="flex md:flex-col flex-col-reverse items-center gap-8">
          <div
            className="flex flex-col items-center space-y-4 md:space-y-8 lg:space-y-12 px-6 py-6 md:px-10 md:py-[4%] lg:px-20 lg:py-[3%]"
            style={{ background: "#FEE182" }}
          >
            <h1 className="monstrrate text-center font-montserrat text-2xl md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-[56px] font-bold uppercase tracking-widest">
              {reviewHeroData.title}
            </h1>

            <img
              src={urlFor(reviewHeroData.heroImage)}
              alt="review-hero-image"
              className="md:h-[25rem] lg:h-[27rem] xl:h-[30rem] h-[15rem] w-full rounded-sm"
            />

            <p
              className="poppins text-center font-poppins text-lg lg:text-xl xl:text-2xl 2xl:text-3xl lg:px-[8%] font-normal tracking-wider lg:mt-8 mt-4"
              style={{ color: "#2D2D2D" }}
            >
              {reviewHeroData.subTitle}
            </p>
          </div>
        </div>
        <div id="review-box" className="top-reviews-container p-6 lg:p-20">
          <h1 className="monstrrate text-2xl md:text-4xl text-black lg:text-4xl xl:text-5xl 2xl:text-7xl font-semibold tracking-tight leading-normal uppercase text-center lg:text-start">
            {reviewHeroData.reviewTitle || "Top Reviews"}
          </h1>
          <div className="filtered-list flex items-center space-x-5 my-[10%] lg:my-[4%]">
            {/* Latest Button */}
            <button
              className={`w-64 py-3 text-center uppercase relative text-[10px] lg:text-lg xl:text-lg 2xl:text-xl font-medium ${
                isActive(reviewHeroData.btnLatest.btnLatestText)
                  ? "font-normal"
                  : ""
              }`}
              onClick={() =>
                filterReviewByTime(reviewCardData, reviewHeroData.btnLatest.btnLatestText)
              }
            >
              <p className="relative z-10 uppercase text-[3vw] font-semibold lg:text-xl 2xl:text-xl poppins">
                {reviewHeroData.btnLatest.btnLatestText}
                {reviewHeroData.btnLatest.btnLatestIcon && (
                  <img
                    className="w-5 absolute lg:bottom-1 bottom-[-2px] right-6 lg:right-16"
                    src={urlFor(reviewHeroData.btnLatest.btnLatestIcon)}
                  />
                )}
              </p>
              <span
                className={`absolute top-0 left-0 right-0 bottom-0 ${
                  isActive(reviewHeroData.btnLatest.btnLatestText)
                    ? "text-gray-900 font-bold bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                    : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
                }`}
              ></span>
            </button>

            {/* Editor Button */}
            <button
              className={`w-64 py-3 text-center uppercase relative text-[10px] lg:text-lg xl:text-lg 2xl:text-xl font-medium ${
                isActive(reviewHeroData.btnEditor.btnEditorText)
                  ? "font-normal"
                  : ""
              }`}
              onClick={() =>
                filterReviewsByTitle(reviewHeroData.btnEditor.btnEditorText)
              }
            >
              <p className="relative z-10 uppercase text-[3vw] font-semibold lg:text-xl 2xl:text-xl poppins">
                {reviewHeroData.btnEditor.btnEditorText}
                {reviewHeroData.btnEditor.btnEditorIcon && (
                  <img
                    className="w-5 absolute top-0 lg:top-1 right-1 lg:right-4"
                    src={urlFor(reviewHeroData.btnEditor.btnEditorIcon)}
                  />
                )}
              </p>
              <span
                className={`absolute top-0 left-0 right-0 bottom-0 py-5 ${
                  isActive(reviewHeroData.btnEditor.btnEditorText)
                    ? "text-gray-900 font-bold bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                    : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
                }`}
              ></span>
            </button>
          </div>
          <section className="review-box-container flex flex-col md:flex-row items-start justify-between mt-8">
            <div className="md:w-[58%] w-full review-box">
              {pageData &&
                filteredReviewsData
                  .slice(startIndex, startIndex + reviewsPerPage)
                  .map((review, index) => (
                    <div key={review.id} className="">
                      <Link
                        to={`/${
                          pageData.reviews ? pageData.reviews : "reviews"
                        }/${review.path}`}
                        className=" flex flex-col md:items-start items-center space-y-5 mb-[2rem] pb-10 border-b-2 "
                      >
                        <img
                          src={urlFor(review.reviewCardImage)}
                          alt={review.authorName}
                          className="w-full h-40 md:h-[30vw] lg:h-[20vw] rounded-2xl"
                        />
                        <div className="flex items-center space-x-4">
                          <p
                            className="poppins text-base lg:text-[21px] leading-10 text-white font-normal text-center  px-4 rounded-sm"
                            style={{ background: "#FCAC10" }}
                          >
                            {review.authorName}
                          </p>
                          <p
                            className="poppins text-base  lg:leading-8 "
                            style={{ color: "#2d2d2d" }}
                          >
                            {convertDateToPrintable(review.releaseTime)}
                          </p>
                        </div>
                        <p
                          className="poppins text-xl  lg:text-2xl 2xl:text-[32px]  2xl:leading-[54px] text-center md:text-start font-semibold"
                          style={{ color: "#2D2D2D" }}
                        >
                          {review.reviewDetailtextContent[0].header}
                        </p>
                        <p className="poppins text-base lg:text-[22px] lg:leading-8 xl:leading-10 2xl:leading-[46px] text-gray-900 font-normal text-center md:text-start">
                          {review.reviewDetailtextContent[0].description[0]}
                        </p>
                      </Link>
                    </div>
                  ))}
            </div>

            <div className="flex md:hidden mx-auto  justify-center space-x-8  mb-6">
              <button
                onClick={handleNextPage}
                // disabled={
                //   startIndex + reviewsPerPage >= filteredReviewsData.length
                // }
                className="w-[40vw] lg:w-[12vw] bg-gradient-to-r from-yellow-500 to-yellow-300 text-gray-700 font-medium lg:text-2xl  py-2 px-4 rounded-md"
              >
                {reviewHeroData.btnNext}
              </button>
            </div>
            <div className="review-box-link flex-col md:w-[35%]">
              {pageData &&
                filteredReviewsData
                  .slice(startIndex, startIndex + reviewsPerPageLink)
                  .map((review, index) => (
                    <Link
                      key={review.id}
                      to={`/${
                        pageData.reviews ? pageData.reviews : "reviews"
                      }/${review.path}`}
                      className="flex flex-col justify-center md:flex-row md:items-center gap-2 md:justify-start items-center mb-8  p-2 w-auto h-auto rounded-xl border border-gray-400 bg-yellow-50"
                    >
                      <img
                        src={urlFor(review.reviewCardImage)}
                        alt={review.authorName}
                        className="md:w-1/2 h-40 w-full  rounded-2xl"
                      />
                      <div className="flex flex-col md:items-start items-center justify-between space-y-4">
                        <p
                          className="monstrrate text-base leading-6 font-medium text-center mt-4 lg:mt-0 px-4 rounded-sm"
                          style={{
                            borderRadius: "7.561px",
                            background: "rgba(255, 175, 0, 0.20)",
                            color: " #B87E00",
                          }}
                        >
                          {review.authorName}
                        </p>

                        <p
                          className="poppins text-base font-medium md:text-start text-center"
                          style={{ color: " #181A2A" }}
                        >
                          {review.reviewDetailtextContent[0].header}
                        </p>
                        <p className="monstrrate text-sm 2xl:text-[.8vw] text-black pb-4 lg:pb-0">
                          {convertDateToPrintable(review.releaseTime)}
                        </p>
                      </div>
                    </Link>
                  ))}
            </div>
          </section>
          <div className="hidden md:flex  justify-center space-x-8  mt-0">
            <button
              onClick={handleNextPage}
              // disabled={
              //   startIndex + reviewsPerPage >= filteredReviewsData.length
              // }
              className="w-[40vw] lg:w-[12vw] bg-gradient-to-r from-yellow-500 to-yellow-300 text-[#2d2d2d] monstrrate font-medium lg:text-2xl  my-12 p-4 rounded-md"
            >
              {reviewHeroData.btnNext}
            </button>
          </div>
        </div>
      </div>
      <HomeConsultant />
      <Footer />
    </>
  );
}

export default ReviewPage;
