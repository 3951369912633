import React, { useState, useEffect } from "react";
import client from "../../sanityCli";
// import image from '../../logo.svg';
import imageUrlBuilder from "@sanity/image-url";
import { v4 as uuidv4 } from "uuid";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function ReviewComment() {
  const [reviewComments, setReviewComments] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [commentInput, setCommentInput] = useState(null);

  let [toUpdate, setToupdate] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    client
      .fetch(`*[_type == 'reviewComment'][0]`)
      .then((data) => {
        setReviewComments(data);
        // console.log("sumit butn", data.submitButton);
        // console.log('data fetched')
      })
      .catch((error) =>
        console.error("Error fetching review comments:", error)
      );
  }, [toUpdate, reviewComments]);

  const fetchComments = async () => {
    try {
      const data = await client.fetch('*[_type == "reviewComment"][0]');
      setCommentData(data);
      if (!data)
        setCommentData(
          data.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime))
        );

      return data; // Return data to potentially use its ID
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  function convertDateToPrintable(date) {
    let dateArray = new Date(date).toString().split(" ");
    let printableDate =
      dateArray[0] +
      " " +
      dateArray[1] +
      " " +
      dateArray[2] +
      " " +
      dateArray[3] +
      " " +
      dateArray[4].slice(0,5); 
     
    return printableDate;
  }

  const postData = async () => {
    // Check if the comment input is not empty
    if (commentInput && commentInput.trim() !== "") {
      try {
        const data = await fetchComments();
        if (data) {
          const newSubmissionData = {
            comment: commentInput,
            time: new Date().toISOString(),
            _key: uuidv4(),
          };
          await appendSubmission(data._id, newSubmissionData);
          // Clear the input box after posting the comment
          setCommentInput("");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      alert('please fill the comment box before submit!🎉')
    }
  };
 
  const appendSubmission = async (documentId, newSubmissionData) => {
    // newSubmissionData._key = uuidv4();
    try {
      await client
        .patch(documentId) // Use the document ID
        .setIfMissing({ commentText: [] }) // Initializes submissions array if it doesn't exist
        .insert("after", "commentText[-1]", [newSubmissionData]) // Appends the new submission
        .commit();
      setTimeout(() => {
        setToupdate(!toUpdate);
      }, 1000);

      alert("successfully Commented.");
    } catch (error) {
      console.error("Error updating and publishing Comment:", error);
    }
  };

  // Function to pick a random image from the x object
  const getRandomImage = () => {
    // Assuming x is an object with an array of images
    const x = reviewComments ? reviewComments.image : [];
    // console.log("image", x);
    const randomIndex = Math.floor(Math.random() * x.length);
    return x[randomIndex].image;
  };

  if (!reviewComments || !reviewComments.submitButton) {
    return <div></div>; // or any other loading indicator
  }

  return (
    <>
     <div className="px-[5%]">
        <h1 className="poppins w-full text-center md:text-start text-2xl font-semibold lg:text-3xl xl:text-4xl 2xl:text-[42px] 2xl:leading-[75px] border-y-2 py-8">{reviewComments.title}</h1>
        <p className="poppins w-full md:w-3/5 mt-4 font-normal lg:text-xl lg:leading-10 2xl:text-2xl 2xl:leading-[48px]">{reviewComments.description}</p>
     </div>
    <div className="my-20 pl-[5%] relative lg:w-[60%]">
      <input
        required 
        id="commentInput"
        type="text"
        placeholder="Add Your Comment"
        className="border-2 border-gray-400 rounded-[2vw] mb-4 lg:rounded-[1vw] w-[90vw] md:w-[65%]  lg:w-full h-16 px-[3%] bg-white text-xl"
        style={{
        
          transition: "border-color 0.3s ease, box-shadow 0.3s ease",
          borderColor: isFocused ? "#007bff" : "#ccc", // Change border color when focused
          boxShadow: isFocused ? 'bg-yellow-500' : "none", // Add box shadow when focused
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={commentInput} // Set input value from state
        onChange={(e) => setCommentInput(e.target.value)} // Update input value on change

      />
   

      <button
        className="absolute w-[20vw] right-[0vw] md:right-[22vw] lg:right-[-15vw] top-2"
        onClick={() => {
          const commentInput = document.getElementById("commentInput").value;
          postData(commentInput);
        }}
      >
        <img
          src={urlFor(reviewComments.submitButton)}
          alt="Submit Button"
          className=""
        />
        
      </button>

      {reviewComments &&
        reviewComments.commentText.map((comment, index) => (
          <div
            key={index}
            className="border-b-2 mb-0 pb-4 md:pb-8 flex flex-col items-stretch md:items-start my-[5%]"
          >
            {" "}
            <div className="flex items-center lg:flex-row lg:items-center justify-center my-2  gap-2 lg:gap-4">
              <img
                src={urlFor(getRandomImage())}
                alt="User"
                className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 2xl:w-16 2xl:h-16 rounded-full"
              />
              <div className="flex flex-col items-center space-y-[2px] lg:space-y-0 lg:flex-row lg:items-center gap-[4%] md:w-[30vw]">
                <p className="text-[10px] leading-[13px] md:text-lg lg:text-lg xl:text-2xl  text-[#000000] font-semibold">
                  {reviewComments.name}
                </p>
                <p className="text-gray-600 text-[1.25vw] lg:text-sm ">
                  {convertDateToPrintable(comment.time)}
                </p>
              </div>
            </div>
            <p className="text-[#4d4d4d] font-normal text-center md:text-start text-[8px] md:text-[10px] lg:text-sm xl:text-xl  lg:text-[1.65vw] lg:leading-[3.3vw] mt-2">
              {comment.comment}
            </p>
          </div>
        ))}
    </div>
    </>
  );
}

export default ReviewComment;
