import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import client from "../../sanityCli"; // Assuming `client` is imported from your Sanity client setup
import imageUrlBuilder from "@sanity/image-url";
import ReviewComment from "./ReviewComment";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";
import Error from "../ErrorPage/Error";
import HomeConsultant from "../Home/homeSec7/HomeConsultant";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function ReviewDetailPage() {
  const [review, setReview] = useState(null);
  const [pageData, setPageData] = useState(null);
  const { id } = useParams();
  let path = id;

  // console.log("path", path);

  useEffect(() => {
    client
      // .fetch(`*[_type == "review" ]`, { id })
      .fetch(
        `
      *[_type == 'review'][0]{
        ...
      }
    `
      )
      .then((data) => {
        data.relatedReviews = data.reviewCardData.filter((r) => r.path != path);
        setReview(data);
        if (!data)
          setReview(
            data.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime))
          );
        // console.log("data", data);
      })
      .catch((error) => {
        console.error("Error fetching review:", error);
      });

    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
        // console.log("pageNavData", result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchPageData();
    window.scrollTo(0, 0);
  }, [path]);

  function convertDateToPrintable(date) {
    let dateArray = new Date(date).toString().split(" ");
    let printableDate =
      dateArray[0] +
      " " +
      dateArray[1] +
      " " +
      dateArray[2] +
      " " +
      dateArray[3] +
      " " +
      dateArray[4];
    return printableDate;
  }

  if (!review) {
    return (
      <div>
        <div>
          <Navbar />
          <Error />
          <Footer />
        </div>
      </div>
    );
  }

  const reviewData = review.reviewCardData.find((r) => r.path === path);
  const reviewCardImage = reviewData?.reviewCardImage;

  return (
    <>
      <Navbar />
      {review.reviewCardData.find((r) => r.path === path) && (
        <div className="review-detail-page ">
          <div className="review-detail-hero-page w-full flex flex-col lg:flex-row items-start gap-5 px-[5%] py-[5%]">
            <div className="review-detail-hero-image w-fll lg:w-2/3 relative">
              <img
                src={urlFor(review.reviewHeroData.heroImage)}
                alt="Review Hero"
                className="overlay-image w-full h-[80vw]  md:h-[65vw] lg:h-[38vw]  lg:w-[58vw] rounded-xl"
              />
              <div
                className="absolute bottom-0 right-0 lg:right-4 left-0 h-[30vw] lg:h-[13vw]  flex flex-col items-start justify-between px-2 py-4 rounded-bl-lg rounded-br-lg"
                style={{
                  background: "rgba(0, 0, 0, 0.10)",
                  backdropFilter: "blur(27.842430114746094px)",
                  flexShrink: 0,
                }}
              >
                <div className="flex items-center gap-2 px-4 py-1 rounded-full bg-white w-fit">
                  <span className="w-2 h-2 md:w-3 md:h-3 lg:h-5 lg:w-5 2xl:w-6 rounded-full bg-yellow-400"></span>
                  <span
                    className=" monstrrate text-[2vw] md:text-sm lg:text-lg 2xl:text-[22.274px] font-normal "
                    style={{ color: "#FFAF00" }}
                  >
                    {review.reviewCardData.find((r) => r.path === path)
                      .developerDetail.gameTitle || "Action & Adventure"}
                  </span>
                </div>
                <div>
                  <p className="monstrrate text-white font-bold leading-normal 2xl:text-[42.957px] xl:text-3xl lg:text-2xl md:text-xl text-[3.2vw] pr-2">
                    {review.reviewCardData.find((r) => r.path === path)
                      .developerDetail.gameDescription ||
                      "Read the latest review for this action pact game and also drop your thoughts at the end!"}
                  </p>
                </div>
                <div className="flex items-center gap-1">
                  <span className="poppins font-normal text-[2.65vw] md:text-[1.5vw] lg:text-lg 2xl:text-[22px] text-slate-50 capitalize">
                    {
                      review.reviewCardData.find((r) => r.path === path)
                        .developerDetail.dateMonth
                    }
                  </span>{" "}
                  <span className="w-1 h-1 bg-white rounded-full"></span>
                  <span className="poppins font-normal text-[2.65vw] md:text-[1.5vw] lg:text-lg 2xl:text-[22px] text-slate-50">
                    {review.reviewCardData.find((r) => r.path === path)
                      .developerDetail.readTime || "10min"}
                  </span>
                </div>
              </div>
            </div>
            <div className="review-detail-right w-full lg:w-1/3 flex flex-col items-start space-y-4 lg:space-y-8">
              <div className="reviewer-detail w-full h-fit rounded-lg bg-gradient-to-r from-yellow-400 to-yellow-600 px-4 py-1">
                <div className="flex flex-row lg:flex-col lg:items-start items-center gap-3">
                  <img
                    src={urlFor(
                      review.reviewCardData.find((r) => r.path === path)
                        .developerDetail.developerImage
                    )}
                    alt={
                      review.reviewCardData.find((r) => r.path === path)
                        .developerDetail.developerName
                    }
                    className="dev-image  xl:w-[8vw] xl:h-[8vw] lg:w-[9vw] lg:h-[9vw] lg:h- md:w-[10vw] md:h-[10vw] w-[17.5vw] h-[17.5vw] rounded-xl"
                  />
                  <div className=" flex flex-col items-start space-y-2 md:space-y-2 lg:space-y-2 border-b-[1px] border-gray-400 w-full pb-4">
                    <p className="poppins text-base font-semibold leading-normal md:text-lg lg:text-2xl xl:text-3xl capitalize text-white">
                      {
                        review.reviewCardData.find((r) => r.path === path)
                          .developerDetail.developerName
                      }
                    </p>
                    <p className="poppins text-[10px] md:text-[12px] lg:text-base xl:text-2xl 2xl:text-2xl text-white">
                      {
                        review.reviewCardData.find((r) => r.path === path)
                          .developerDetail.role
                      }
                    </p>
                  </div>
                </div>
                <p className="poppins  text-white font-normal my-3 text-[11px] leading-[13.5px] md:text-sm lg:text-lg xl:text-xl 2xl:text-[22.4px] 2xl:leading-7">
                  {
                    review.reviewCardData.find((r) => r.path === path)
                      .developerDetail.role2
                  }
                </p>
              </div>
              <div className="w-full flex flex-col lg:items-start items-center lg:justify-center justify-center space-y-2  bg-gradient-to-r from-yellow-500 to-yellow-400 rounded-lg p-4">
                <p className="railway text-gray-100 text-base  lg:text-xl xl:text-2xl 2xl:text-[25px]  font-seminold">
                  {
                    review.reviewCardData.find((r) => r.path === path)
                      .developerDetail.followReviewer
                  }
                </p>
                <div className="social-media md:item-center items-start space-x-4 flex ">
                  {review.reviewCardData
                    .find((r) => r.path === path)
                    .developerDetail.socialMediaLink.map(
                      (socialIcon, index) => (
                        <Link
                          key={index}
                          to={socialIcon.iconLink}
                          target="blanck"
                        >
                          <img
                            src={urlFor(socialIcon.icon)}
                            alt="Social Media Icon"
                            className="lg:w-12 w-8"
                          />
                        </Link>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="review-details px-[5%] py-10 mt-0 md:mt-2 xl:mt-6">
            <div className="w-full game-info flex flex-wrap items-start justify-evenly  py-4 lg:py-6 border-y-2">
              {review.reviewCardData
                .find((r) => r.path === path)
                .gameInfo.map((gameDetails, index) => (
                  <div
                    key={index}
                    className={`flex-shrink-0 flex flex-col items-center md:pr-4 space-y-4 w-1/2 lg:w-1/4 ${
                      (index + 1) % 4 !== 0
                        ? "border-r-2 lg:border-r-2 mb-8 md:mt-2"
                        : "border-r-0 mb-8 md:mt-2"
                    } `}
                  >
                    <p className="monstrrate text-sm lg:text-2xl lg:leading-[54px] text-[#969696] font-medium uppercase">
                      {gameDetails.position}
                    </p>
                    <img
                      src={urlFor(gameDetails.icon)}
                      alt={gameDetails.name}
                      className="w-6 h-6 rounded-full"
                    />
                    <p className="poppins lg:text-[16px] lg:leading-[54px]  font-semibold text-[#969696] text-center">
                      {gameDetails.name}
                    </p>
                  </div>
                ))}
            </div>

            <div className="game-name-rating flex items-center justify-between py-4 px-8 my-12 bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-2xl">
              <p className="poppins text-lg lg:text-2xl xl-text-[32px] text-white font-semibold">
                {review.reviewCardData[0].gameName}
              </p>
              <p className="monstrrate text-white text-xl lg:text-3xl">
                {review.reviewCardData.find((r) => r.path === path).gameRating}
              </p>
            </div>
            <div className="mt-8 lg:mt-20">
              {review.reviewCardData
                .find((r) => r.path === path)
                .reviewDetailtextContent.map((content, index) => (
                  <div key={index}>
                    <h1
                      className="poppins text-xl lg:text-4xl 2xl:text-[42px] font-semibold font-montserrat leading-tight"
                      style={{ color: "#1B1B1F" }}
                    >
                      {content.header}
                    </h1>
                    {content.description.map((desc, idx) => (
                      <p
                        key={idx}
                        className="poppins my-4 lg:my-8 text-sm lg:text-xl font-normal font-montserrat leading-tight"
                      >
                        {desc}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          <ReviewComment />

          <div
            className="review-rating-card m-4 p-4 lg:pl-12 lg:pr-8 lg:pb-12 flex flex-col-reverse lg:flex-row  items-start bg-white lg:mx-20 lg:my-20 lg:py-20 rounded-xl border-2"
            style={{
              borderRadius: "3vw",
              border: "1px solid #CFCFCF",
              background: "#FFF",
              boxShadow:
                "0px 1px 2px 0px rgba(0, 0, 0, 0.07), 0px 2px 4px 0px rgba(0, 0, 0, 0.07), 0px 4px 8px 0px rgba(0, 0, 0, 0.07), 0px 8px 16px 0px rgba(0, 0, 0, 0.07), 0px 16px 32px 0px rgba(0, 0, 0, 0.07), 0px 32px 64px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div className="lg:w-2/3 w-full flex-col items-start">
              <div className="hidden lg:block mb-12">
                <strong className="monstrrate text-4xl text-black font-bold">
                  {
                    review.reviewCardData.find((r) => r.path === path)
                      .reviewRating.reviewName
                  }
                  {/* Access review rating gameName */}
                </strong>
              </div>
              <div className="flex lg:flex-row flex-col items-center justify-center">
                <div className="lg:w-1/4 w-full flex-col lg:items-center lg:justify-between  space-y-3">
                  {/* Display other review rating details */}
                  <p className="monstrrate bg-[#FCAC10] my-4 mx-auto lg:mx-0 w-20 h-20 lg:w-32 lg:h-32 xl:w-36 xl:h-36 2xl:w-40 2xl:h-40 rounded-full text-center text-3xl flex items-center justify-center lg:text-5xl xl:text-6xl 2xl:text-8xl text-white font-bold ">
                    {
                      review.reviewCardData.find((r) => r.path === path)
                        .reviewRating.ratingNumber
                    }
                  </p>
                  <p className="hidden capitalize lg:flex items-start monstrrate bg-black p-4  rounded-full text-white text-2 xl font-semibold w-fit">
                    <span className="mx-1">
                      ❤️
                      <img
                        src={
                          review.reviewCardData.find((r) => r.path === path)
                            .reviewRating.icon
                        }
                        className="w-4"
                      />
                    </span>
                    <span>
                      {
                        review.reviewCardData.find((r) => r.path === path)
                          .reviewRating.choice
                      }
                    </span>
                  </p>
                  {pageData && (
                    <Link
                      to={`/${
                        // pageData.reviews ? pageData.reviews : "reviews"
                        pageData.ratingScale
                          ? pageData.ratingScale
                          : "scalerating"
                      }`}
                      state={review.reviewCardData.find((r) => r.path === path)}
                      className="hidden lg:block text-amber-500 underline text-2xl font-semibold ml-2"
                    >
                      {
                        review.reviewCardData.find((r) => r.path === path)
                          .reviewRating.reviewScore
                      }
                    </Link>
                  )}
                </div>
                <div className="lg:w-3/4 w-full">
                  <p className="monstrrate text-2xl my-2 lg:my-0 text-center lg:text-start lg:text-3xl xl:text-4xl 2xl:text-5xl text-black font-bold">
                    {
                      review.reviewCardData.find((r) => r.path === path)
                        .reviewRating.title
                    }
                  </p>
                  <p className="poppins text-sm md:text-base lg:text-xl 2xl:text-2xl text-center lg:text-start text-gray-700 font-normal mt-5 md:pr-20">
                    {
                      review.reviewCardData.find((r) => r.path === path)
                        .reviewRating.subTitle
                    }
                  </p>

                  <p className="block capitalize mx-auto my-4 lg:hidden monstrrate bg-black p-4  rounded-full text-white text-2 xl font-semibold w-fit">
                    <span className="mx-1">❤️</span>

                    {
                      review.reviewCardData.find((r) => r.path === path)
                        .reviewRating.choice
                    }
                  </p>
                  {pageData && (
                    <Link
                      to={`/${
                        // pageData.reviews ? pageData.reviews : "reviews"
                        pageData.ratingScale
                          ? pageData.ratingScale
                          : "scalerating"
                      }`}
                      state={review.reviewCardData.find((r) => r.path === path)}
                      className="block text-center lg:hidden text-amber-500 text-2xl font-semibold"
                    >
                      {
                        review.reviewCardData.find((r) => r.path === path)
                          .reviewRating.reviewScore
                      }
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 w-full">
              <img
                className="lg:w-[40vw] lg:h-[40vh] h-40 md:h-60 md:w-full w-100% rounded-xl"
                src={urlFor(
                  review.reviewCardData.find((r) => r.path === path)
                    .reviewRating.reviewImage
                )}
                alt={
                  review.reviewCardData.find((r) => r.path === path)
                    .reviewRating.gameName
                }
              />
            </div>
          </div>
        </div>
      )}
      <HomeConsultant />
      <Footer />
    </>
  );
}

export default ReviewDetailPage;
