import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  BrowserRouter as Router,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import HomeComponents from "./components/Home/HomeComponents";
import BlogComponents from "./components/Blog/BlogComponents";
import BlogDetail from "./components/Blog/BlogDetail";
import PrivacyPolicy from "./components/PrivacyPolicy/PrvicayPolicy.js";
import CookiesPolicy from "./components/CookiesPolicy/CookiesPolicy";
import imageUrlBuilder from "@sanity/image-url";
import client from "./sanityCli.js";
import ReviewPage from "./components/Reviews/ReviewPage.js";
import ReviewDetailPage from "./components/Reviews/ReviewDetailPage.js";
import RatingScale from "./components/Reviews/RatingScale.js";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const defaultRoutes = [
  { path: "/", element: <HomeComponents /> },
  { path: "home", element: <HomeComponents /> },
  { path: "/privacyPolicy", element: <PrivacyPolicy /> },
  { path: "/cookiesPolicy", element: <CookiesPolicy /> },
  { path: "/reviews", element: <ReviewPage /> },
  { path: "/reviews/:id", element: <ReviewDetailPage /> },
  { path: "/scalerating", element: <RatingScale /> },
  { path: "/blog", element: <BlogComponents /> },
  { path: "/blog/:id", element: <BlogDetail /> },
];

const Initialize = () => {
  const [routes, setRoutes] = useState(defaultRoutes);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const data = await client.fetch('*[_type =="pageContent"][0]');
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return null;
      }
    };
    fetchPageContent()
      .then((data) => {
        if (data) {
          const dynamicRoutes = [
       
            { path: "/", element: <HomeComponents /> },
            {
              path: `/${data.home ? data.home : "home"}`,
              element: <HomeComponents />,
            },
            {
              path: `/${
                data.privacyPolicy ? data.privacyPolicy : "privacyPolicy"
              }`,
              element: <PrivacyPolicy />,
            },
            {
              path: `/${data.reviews ? data.reviews : "reviews"}`,
              element: <ReviewPage />,
            },
            {
              path: `/${data.blog ? data.blog : "blog"}/:id`,
              element: <BlogDetail />,
            },
            {
              path: `/${data.reviews ? data.reviews : "reviews"}/:id`,
              element: <ReviewDetailPage />,
            },
            {
              path: `/${data.ratingScale ? data.ratingScale : "scalerating"}`,
              element: <RatingScale />,
            },
            {
              path: `/${
                data.cookiesPolicy ? data.cookiesPolicy : "cookiesPolicy"
              }`,
              element: <CookiesPolicy />,
            },
            {
              path: `/${data.blog ? data.blog : "blog"}`,
              element: <BlogComponents />,
            },
          ];

          setRoutes(dynamicRoutes.length > 0 ? dynamicRoutes : defaultRoutes);
          document.title = data.title;
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", data.description);
          if (data.favicon) {
            let link =
              document.querySelector("link[rel*='icon']") ||
              document.createElement("link");
            link.type = "image/png";
            link.rel = "shortcut icon";
            link.href = urlFor(data.favicon);
            document.getElementsByTagName("head")[0].appendChild(link);
          }

          if (data.header && data.header.length > 0) {
            data.header.forEach((headerHtml) => {
              const div = document.createElement("div");
              div.innerHTML = headerHtml;
              while (div.children.length > 0) {
                document.head.appendChild(div.children[0]);
              }
            });
          }
        }
      })
      .catch((error) =>
        console.error("ErrorDocument 404 /notfound.html", error)
      );
  }, [navigate]);

  const element = useRoutes(routes);

  return element;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Initialize />
    </Router>
  </React.StrictMode>
);
