

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";
import Error from "../ErrorPage/Error";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function BlogDetail() {
  const [blogData, setBlogData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [mostViewedBlogs, setMostViewedBlogs] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`
          *[_type == 'blogs']{
            ...
          }
        `);
        setBlogData(data);
        // console.log("blogData", data);

        // Fetch the five most viewed blogs
        const mostViewed = await client.fetch(`
          *[_type == 'blogs'][0].blogData | order(views asc)[0...5]
        `);
        setMostViewedBlogs(mostViewed);
        // console.log("mostViewedBlogs", mostViewed);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
    fetchPageData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  if (!blogData) return <Error />;

  const blog = blogData[0]?.blogData.find((blog) => blog.path === id);
  if (!blog) {
    // console.log("Blog not found");
    return <Error />;
  }
  // console.log("blogData:", blogData[0]?.blogData);
  
  // console.log('blogId:', blog.id);
  
  const mostViewedTitles = blogData[0]?.mostViewedTitles || [];
  // console.log("mostViewedBlogs:", mostViewedBlogs);
  
  // Merge most viewed titles with the corresponding most viewed blogs
  const mergedMostViewed = mostViewedBlogs.map((mostViewedBlog) => {
    const titleObj = mostViewedTitles.find((title) => title.id === mostViewedBlog.id.toString());
    return { ...mostViewedBlog, title: titleObj ? titleObj.title : "" };
  });

  // console.log("mostViewedTitles:", mostViewedTitles);
  return (
    <>
      <Navbar />
      <div className="blog-detail-container w-full relative px-[5%] lg:px-0">
        <section className="flex w-full lg:w-3/4 lg:ml-[8vw] my-[4%]  flex-col items-center space-y-6 justify-center ">
          <p
            className="monstrrate uppercase text-sm md:text-base font-semibold"
            style={{ color: "#707070" }}
          >
            {blog.type}
          </p>
          <h1
            className="poppins uppercase text-2xl lg:text-3xl xl:text-4xl 2xl:text-[55px] 2xl:leading-[80px] text-center font-semibold"
            style={{ color: "#000000" }}
          >
            {blog.blogHeader}
          </h1>
          <span className="monstrrate text-[10px] xl:text-base font-semibold uppercase text-[#707070] flex items-center gap-2">
            <span>{blog.blogReleaseDate} </span>
            <span className="w-1 h-1 rounded-full bg-[#707070]"></span>
            <span>{blog.blogReleaseTime}</span>
          </span>
        </section>
        <div className="w-full md:w-3/4 lg:ml-[8vw] my-[5%] ">
          <div className="w-full">
            {blog.blogImage && (
              <img
                src={urlFor(blog.blogImage)}
                alt={blog.blogTypes}
                className="w-full h-40 md:h-64 lg:h-[30vw]"
              />
            )}
          </div>
          <div className="author-icon-social-media py-[6%] lg:py-[3%] flex items-center justify-between border-b-2">
            <div className="flex items-center gap-2">
              {blog.blogAuthorImage && (
                <img
                  src={urlFor(blog.blogAuthorImage)}
                  alt={blog.authorName}
                  className="w-8 h-8 lg:w-16 lg:h-16 xl:w-20 xl:h-20 2xl:text-w-[106px] 2xl:text-h-[106px] rounded-full"
                />
              )}
              <div className="flex flex-col items-start lg:gap-2">
                <p
                  className="monstrrate capitlize text-[2.2vw] md:text-lg lg:text-xl xl:text-2xl font-semibold"
                  style={{ color: "#2D2D2D" }}
                >
                  {blog.blogAuthor}
                </p>
                <p
                  className="poppins text-[2vw] lg:text-base capitalize"
                  style={{ color: "#555555" }}
                >
                  {blog.type}
                </p>
              </div>
            </div>
            <div className="author-social-link flex gap-8 ">
              {blog.authorSocialIcon &&
                blog.authorSocialIcon.map((item) => (
                  <Link
                    to={item.link}
                    target="_blank"
                    key={item._key}
                    className="flex items-center w-5 h-5 lg:w-8 lg:h-8"
                  >
                    {item.icon && (
                      <img src={urlFor(item.icon)} alt="Social Icon" />
                    )}
                  </Link>
                ))}
            </div>
          </div>
          <div className="blog-detail-text my-12 flex items-start gap-12">
            <div className="w-full lg:w-full">
              <p className="poppins text-base md:text-xl 2xl:text-2xl font-normal text-[#000] mb-4">
                {blog.paragraph}
              </p>
              {blog.blogDetailTextContent.map((blogDetailText, id) => (
                <div key={id} className="">
                  <h1 className="monstrrate text-2xl text-gray-900 my-8 font-semibold">
                    {blogDetailText.header}
                  </h1>
                  {blogDetailText.image && (
                    <div className="w-full">
                      <img
                        src={urlFor(blogDetailText.image)}
                        alt="Blog Detail"
                        className="w-full h-[40vw] lg:h-[30vw] mb-4"
                      />
                    </div>
                  )}
                  <p className="poppins text-base md:text-xl lg:text-xl 2xl:text-2xl font-normal text-[#000] mb-0">
                    {blogDetailText.description}
                  </p>
                </div>
              ))}
            </div>

            <div className="hidden lg:block w-1/3">
              <h1 className="monstrrate text-xl text-gray-800 uppercase mb-6 font-semibold">
              MOST VIEWED STORIES
              </h1>
              <ul className="w-full flex flex-col items-start gap-12">
              {mergedMostViewed.map((mostViewedBlog, index) => (
                  <li key={mostViewedBlog.id} className="w-full">
                    <Link
                       to={`/${pageData.blog ? pageData.blog : 'blog'}/${mostViewedBlog.path}`}

                      className={
                        index === 0
                          ? "flex flex-col-reverse gap-4 border-2"
                          : "flex items-center  border-b pb-4"
                      }
                    >
                      <div className=" w-full flex items-start justify-start gap-2 p-2">
                        <div>
                          <span className={"text-lg text-black font-semibold"}>
                            {index + 1}
                          </span>
                        </div>
                        <div className="flex flex-col items-start w-full mt-1">
                          <strong
                            className={
                              index === 0
                                ? "poppins uppercase tracking-widest text-amber-400 text-[14px] leading-5 font-semibold"
                                : "poppins font-bold uppercase tracking-widest text-amber-400 text-[12px] leading-4"
                            }
                          >
                            {mostViewedBlog.title}
                          </strong>
                          <p
                            className={
                              index === 0
                                ? "poppins capitalize  text-[16px] leading-8 font-bold text-black"
                                : "poppins text-gray-800 capitalize mt-[2px] text-[16px] leading-7 font-normal"
                            }
                          >
                            {mostViewedBlog.blogHeader.slice(0, 30)}...
                          </p>
                        </div>
                      </div>
                      <div>
                        {mostViewedBlog.blogImage && (
                          <img
                            src={urlFor(mostViewedBlog.blogImage)}
                            alt={blog}
                            className={
                              index === 0
                                ? "w-full h-48"
                                : "w-32 h-20 rounded-lg"
                            }
                          />
                        )}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="absolute top-56 right-20 hidden lg:block">
          {blogData[0].socialMediaIcons &&
            blogData[0].socialMediaIcons.map((item, id) => (
              <a
                key={id}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={urlFor(item.image)}
                  alt="Social Media Icon"
                  className="w-12 "
                />
              </a>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BlogDetail;
