import React, { useState, useEffect } from "react";
import client from "../../../sanityCli";
import "./HomeHero.css";
// import imageUrlBuilder from "@sanity/image-url";

// const builder = imageUrlBuilder(client);

// function urlFor(source) {
//   return builder.image(source);
// }

export default function HomeHeroBottom() {
  const [text, setText] = useState(""); // State to hold the fetched text

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "homeHeroBottom"]');

        setText(result[0]?.text || ""); // Access the 'text' field from the fetched result
        // setBottomItems(result[0]?.bottomItems || []); // Access the 'bottomItems' array from the fetched result

        // Calculate the total item count and set CSS variables
          // Set CSS variable for item count
          // document.documentElement.style.setProperty("--item-count", result[0]?.bottomItems.length || 0);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  return (

    // <div className="slider flex items-center relative h-[5vw] ">
    //   <div className="slide-track absolute">
    //     {bottomItems.map((item, index) => (
    //       <div key={index} className="slide w-1/2 lg:w-[30vw]  py-12">
    //         {item.image && (
    //           <img src={urlFor(item.image)} alt={`bottom-item-${index}`} />

    //         )}
    //         <p>{item.text}</p>
    //       </div>
    //     ))}
    //   </div>   
    // </div>
    <div className="slider mopnstrrate border-[0.5px] border-gray-600 text-center font-semibold text-[12px] md:text-lg lg:text-2xl xl:text-3xl 2xl:text-[32px] leading-[30px] py-2 lg:py-4" style={{color: '#2D2D2D'}}>
         {text}
    </div>
  );
}
