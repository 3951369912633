import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../sanityCli";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeOurTeam() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [navdata, setNavData] = useState(null);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const bgColors = [
    "rgba(103, 25, 62, 1)",
    "rgba(134, 190, 114, 1)",
    "rgba(197, 36, 197, 1)",
    "#0498AC"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "teamMember"]');
        const { title, subTitle, members } = result[0] || {};
        setTitle(title || "");
        setSubTitle(subTitle || "");
        setTeamMembers(members || []);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    const fetchNavData = async () => {
      try {
        const result = await client.fetch('*[_type == "navbarContent"][0]');
        setNavData(result);
        // console.log('navdata', result)
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchNavData();

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-6 lg:gap-12 items-center py-8 mt-0 lg:my-[0%]" id="team">
      <div className="flex flex-col items-center space-y-4">
        {/* <p className="text-yellow-500 text-2xl font-semibold my-8">{title}</p> */}
        <p className="monstrrate text-yellow-500 text-sm md:text-base lg:text-2xl font-semibold my-2 hover:underline transform hover:scale-105 hover:cursor-alias transition-transform">
          {title}
        </p>

        <h4 className="monstrrate text-xl lg:text-4xl font-bold text-slate-900 tracking-wider">
          {subTitle}
        </h4>
      </div>

      <div className="py-4 flex items-center justify-center flex-wrap gap-[1%]  w-full lg:w-full lg:h-full ">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={`team-member relative lg:w-[21vw] w-[45%] lg:h-[440px] xl:h-[520px] 2xl:h-[685px] h-[260px] flex flex-col items-center justify-between gap-2 lg:gap-4 py-4 px-1 rounded-xl text-white my-2 mx-1 lg:mx-0` }
            style={{ backgroundColor: bgColors[index] }}
          >
            <p className="poppins text-[14px] md:text-base lg:text-2xl font-semibold text-slate-100 ">
              {member.name}
            </p>
            {member.image && (
              <div className="lg:w-[100%]  lg:h-80">
                <img
                  src={urlFor(member.image)}
                  alt={member.name}
                  className="w-[32vw] h-[35vw] lg:w-full lg:h-full object-contain"
                />
              </div>
            )}
            <h3 className="poppins text-[12px] leading-5  md:text-sm lg:text-xl font-normal text-center px-[5%]">
              {member.role}
            </h3>
            <div className="flex items-center justify-between gap-2">
              {member.linkedinIcon && (
                <Link to={member.linkedinurl} target="blanck">
                  {" "}
                  <img
                    src={urlFor(member.linkedinIcon)}
                    alt={member.name}
                    className="w-[6vw] md:w-[4vw] lg:w-[3vw]"
                  />
                </Link>
              )}
              {member.fbIcon && (
                <Link to={member.fbUrl} target="blanck">
                  {" "}
                  <img
                    src={urlFor(member.fbIcon)}
                    alt={member.name}
                    className="w-[6vw] md:w-[4vw] lg:w-[3vw]"
                  />{" "}
                </Link>
              )}
              {member.instaIcon && (
                <Link to={member.instaUrl} target="blanck">
                  <img
                    src={urlFor(member.instaIcon)}
                    alt={member.name}
                    className="w-[6vw] md:w-[4vw] lg:w-[3vw]"
                  />{" "}
                </Link>
              )}
              {member.xIcon && (
                <Link to={member.xUrl} target="blanck">
                  {" "}
                  <img
                    src={urlFor(member.xIcon)}
                    alt={member.name}
                    className="w-[6vw] md:w-[4vw] lg:w-[3vw]"
                  />
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
