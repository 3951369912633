import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../sanityCli";
import image from './Frame 1000008412 (2).svg';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeFaq({ faqData }) {
  const [faqItems, setFaqItems] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [icon, setIcon] = useState(null);
  const [toggleIcon, setToggleIcon] = useState(null);

  useEffect(() => {
    if (faqData) {
      const { title, subtitle, faqItems, icon, toggleIcon } = faqData;
      setTitle(title || "");
      setSubtitle(subtitle || "");
      setFaqItems(faqItems || []);
      setIcon(icon || null);
      setToggleIcon(toggleIcon || null);
    }
  }, [faqData]);

  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpansion = (index) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }));
  };

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    // console.log("section", sectionId);
    if (location.pathname !== "/") {
      // Navigate to the home page first
      navigate("/");
      // After navigating to home, scroll to the specified section
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the delay time as needed
    } else {
      // Scroll to the specified section with smooth behavior
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="w-full py-[5%] mb-[8%] lg:mb-[5%] flex flex-col items-center justify-center space-y-8">
      <div className="flex flex-col items-center justify-center md:space-y-8 space-y-4 my-[2%]">
        <h1 className="monstrrate text-2xl  md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold leading-tight text-black text-center">
          {title}
        </h1>
        <p className="poppins text-[12px] md:text-xl lg:text-2xl text-center font-normal leading-relaxed text-gray-700">
          {subtitle}
        </p>
      </div>
      <div className="w-full px-6 md:px-16">
        {faqItems.map((item, index) => (
          <div
            key={index}
            className={`w-full py-4 border-t border-gray-400 ${
              index === faqItems.length - 1 ? "border-b" : ""
            }`}
          >
            <div className="flex items-center justify-between gap-4 py-4 md:py-4">
              <p className="poppins text-[12px] md:text-2xl md:font-normal font-normal leading-tight text-black">
                {item.question}
              </p>
              <span
                className={`bg-yellow-50 text-black flex items-center justify-center lg:w-16 lg:h-16 w-8 h-6 rounded-full cursor-pointer ${
                  expandedItems[index] ? "bg-yellow-200 text-gray-600" : ""
                }`}
                style={{background: "#FAEFDC"}}
                onClick={() => toggleExpansion(index)}
              >
                {expandedItems[index] ? (
                  <img src={urlFor(icon)} alt="collapse" className="w-3 md:w-5 lg:w-7"/>
                ) : (
                  <img src={urlFor(toggleIcon)} alt="expand" className="w-3 md:w-5 lg:w-7"/>
                )}
              </span>
            </div>
            {expandedItems[index] && (
              <p className="poppins pb-4 mt-2 md:mt-0 md:text-base font-medium text-[10px] leading-relaxed text-gray-700 w-[90%]">
                {item.answer}
              </p>
            )}
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center">
        <img src={image} alt="enquiry-btn" className="w-[80%] lg:mt-8 cursor-pointer" onClick={() => handleNavLinkClick('contact')}/>
      </div>
    </div>
  );
}
