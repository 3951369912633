import React, { useState, useEffect } from "react";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";
import HomeConsultant from "../Home/homeSec7/HomeConsultant";
import HomeSubscribe from "../UnusedComponents/homeSec8/HomeSubscribe";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function RatingScale() {
  const [ratingData, setRatingData] = useState(null);

  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const ratingPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "reviewScale"][0]');
        // console.log("result", result);
        setRatingData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    ratingPageData();
  }, []);

  return (
    <>
    <Navbar />
      <div className="rating-Scale my-8 px-[5vw]">
        {ratingData && (
          <div className="heropage flex flex-col items-center justify-center space-y-[5%] lg:space-y-[4%] mb-[5%]">
            <h1 className="monstrrate text-gray-900 text-center text-[4.75vw] md:text-2xl lg:text-[3vw] font-bold uppercase ">
              {ratingData.title}
            </h1>
            <h1 className="poppins text-gray-800 text-center text-[3.5vw] leading-[6vw] md:text-base lg:text-[1.75vw] px-[5vw] md:px-[10vw] lg:px-[20vw] mt-[1vw] lg:leading-[2.5vw]">
              {ratingData.subTitle}
            </h1>
            <div className="w-[90vw] h-[20vh] lg:h-[16vw]  my-[4%]">
              {ratingData.gameImg && <img src={urlFor(ratingData.gameImg)} alt='/' className="w-full h-full"/>}
            </div>
          </div>
        )}
        {ratingData && (
          <div className="header flex flex-col items-center lg:items-start space-y-5 mt-[4%] border-b-2 pb-[4%]">
            <h1 className="monstrrate text-gray-900 text-[4.75vw] leading-[12vw] md:text-2xl lg:text-[3vw] lg:leading-[3vw] font-semibold">
              {ratingData.heading}
            </h1>
            <h1 className="poppins text-gray-900 lg:text-start text-center font-normal text-[4vw] leading-[6.5vw] md:text-xl lg:text-[1.25vw] lg:leading-[2.5vw]">
              {ratingData.description}
            </h1>
          </div>
        )}
        {ratingData && (
          <div className="flex flex-col items-start space-y-10 my-[5%]">
            {ratingData.scaleDescription.map((scale, index) => (
              <div
                key={index}
                className="flex-col items-center space-y-[4%] lg:space-y-0 flex lg:flex-row lg:items-start lg:gap-[4%] border-b-2 py-[8%] lg:py-[4%] w-full"
              >
                <div className="flex flex-col lg:items-start items-center space-y-5">
                  <div className="flex items-center justify-center lg:justify-start space-x-3">
                    <p className="w-[20vw] h-[20vw] md:w-[12vw] md:h-[12vw] lg:w-[7.5vw] lg:h-[7.5vw] rounded-full bg-black text-white flex items-center justify-center">
                      <span className="poppins text-[10vw] md:text-[7vw] lg:text-[4vw] font-bold">
                        {scale.rating}
                      </span>
                    </p>
                    <p className="poppins capitalize text-[7.75vw] leading-[15vw] md:text-3xl  lg:text-[3vw] lg:leading-[6vw] font-semibold">
                      {scale.ratingTitle}
                    </p>
                  </div>
                  <p className="poppins text-gray-900 lg:text-start text-center font-normal text-[4vw] leading-[6.5vw] md:text-xl lg:text-[1.25vw] lg:leading-[2.5vw]">
                    {scale.ratingText}
                  </p>
                </div>

                {scale.ratingImage && (
                  <img
                    src={urlFor(scale.ratingImage)}
                    alt="/"
                    className="w-[90vw] h-[60vw] lg:w-[30vw] lg:h-[30vw] rounded-sm"
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <HomeConsultant />
  
      <Footer />
    </>
  );
}

export default RatingScale;
