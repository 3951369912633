import React, { useState, useEffect } from "react";
import client from "../../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function CardsGames() {
  const [partnerCards, setPartnerCards] = useState([]);
  const [upcomingGamesCards, setUpcomingGamesCards] = useState([]);
  const [activeSection, setActiveSection] = useState("upcomingGames");
  const [startIndex, setStartIndex] = useState(0);
  const [showSingleCard, setShowSingleCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [partnerData, setPartnerData] = useState({});

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const partnerData = await client.fetch(`*[_type == "partner"]`);
        const upcomingGamesData = await client.fetch(
          `*[_type == "upcomingGame"]`
        );
        setPartnerCards(partnerData[0]?.partnerCard || []);
        setUpcomingGamesCards(upcomingGamesData[0]?.upcomingGamesCard || []);
        setPartnerData(partnerData[0] || {});
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cards:", error);
        setLoading(false);
      }
    };

    fetchCards();
  }, []);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setStartIndex(0); // Reset startIndex when section changes
  };

  const scrollLeft = () => {
    if (startIndex === 0) return;
    const prevIndex = startIndex - (showSingleCard ? 1 : 6);
    setStartIndex(prevIndex >= 0 ? prevIndex : 0);
  };

  const scrollRight = () => {
    const currentCards =
      activeSection === "upcomingGames" ? upcomingGamesCards : partnerCards;
    const cardLength = currentCards.length;
    const nextIndex = startIndex + (showSingleCard ? 1 : 6);
    setStartIndex(nextIndex < cardLength ? nextIndex : 0);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      scrollRight();
    }, 6000);

    return () => clearInterval(intervalId);
  }, [startIndex, showSingleCard, activeSection]);

  useEffect(() => {
    const handleResize = () => {
      setShowSingleCard(window.innerWidth < 840);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return <div></div>;
  }
  const { topGamesTitle, upcomingTitle, ourPartnerTitle, leftIcon, rightIcon } =
    partnerData;

  return (
    <div id="topGames" className="top-games-container mt-[2px] mb-[12%]">
      <div className="flex items-center justify-center px-[4vw] lg:px-[20vw] py-2 my-[5%]  w-full" style={{background: 'linear-gradient(88deg, rgba(246, 175, 33, 0.40) -13.21%, rgba(254, 244, 23, 0.40) 148.7%)'}}>
        <h1 className="monstrrate text-[5vw] md:text-2xl lg:text-[3.5vw] lg:leading-[3.85vw] font-semibold text-center uppercase" style={{color: '#000000'}}>
          {topGamesTitle || "Welcome to the Top Games"}
        </h1>
      </div>
      <div className="flex items-center justify-center gap-4 my-[2%] lg:my-[5%] lg:gap-10">
        <button
          className={`md:w-[40vw] lg:w-[25vw] p-4 uppercase relative font-medium ${
            activeSection === "upcomingGames"
            ? "text-[3.8vw] xl:text-2xl 2xl:text-[32px] font-semibold text-[#2d2d2d]"
            : "text-[3.8vw] md:text-xl xl:text-2xl 2xl:text-[32px] "
          }`}
          onClick={() => handleSectionChange("upcomingGames")}
        >
          <span className="poppins relative z-10 uppercase">
            {" "}
            {upcomingTitle || "Upcoming Games"}
          </span>
          <span
            className={`absolute top-0 left-0 right-0 bottom-0 ${
              activeSection === "upcomingGames"
                ? "bg-gradient-to-r from-yellow-500 to-yellow-300 transform rounded-full z-0"
                : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform rounded-full z-0"
            }`}
          ></span>
        </button>
        <button
          className={`md:w-[40vw] lg:w-[25vw] p-4 uppercase relative font-medium ${
            activeSection === "ourPartner"
            ? "text-[3.8vw] xl:text-2xl 2xl:text-[32px] font-semibold text-[#2d2d2d]"
            : "text-[3.8vw] md:text-xl xl:text-2xl 2xl:text-[32px] "
          }`}
          onClick={() => handleSectionChange("ourPartner")}
        >
          <span className="poppins relative z-10">
            {ourPartnerTitle || "Our Partner"}
          </span>
          <span
            className={`absolute top-0 left-0 right-0 bottom-0 ${
              activeSection === "ourPartner"
                ? "bg-gradient-to-r from-yellow-500 to-yellow-300 transform rounded-full z-0"
                : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform rounded-full z-0"
            }`}
          ></span>
        </button>
      </div>
      <div className="cards-container w-full relative">
        <>
          <button
            className="absolute  z-50 lg:left-6 left-[38%] lg:top-[50%] top-[104%] text-white text-3xl bg-gray-800 w-[10vw] h-[10vw] md:w-[8vw] md:h-[8vw] lg:w-[4vw] lg:h-[4vw] rounded-full flex items-center justify-center"
            onClick={scrollLeft}
          >
            {partnerData.leftIcon && (
              <img
                src={urlFor(partnerData.leftIcon)}
                alt="Left Slide"
                className="lg:w-7 lg:h-7 md:w-5 md:h-5 w-3 h-3"
              />
            )}
          </button>
          <button
            className="absolute lg:right-6 right-[38%] lg:top-[50%] top-[104%] text-white text-3xl bg-gray-800 w-[10vw] h-[10vw] md:w-[8vw] md:h-[8vw] lg:w-[4vw] lg:h-[4vw] rounded-full flex items-center justify-center"
            onClick={scrollRight}
          >
            {partnerData.rightIcon && (
              <img
                src={urlFor(partnerData.rightIcon)}
                alt="Right Slide"
                className="lg:w-7 lg:h-7 md:w-5 md:h-5 w-3 h-3"
              />
            )}
          </button>
        </>
        <div
          id="ourPartner"
          className={`${
            activeSection === "ourPartner" ? "block" : "hidden"
          } our-partner flex items-center flex-wrap justify-center gap-[2%]`}
        >
          {partnerCards.map((card, index) => (
            <div
              key={index}
              style={{
                background: "linear-gradient(180deg, #36363E 0%, #292930 100%)",
              }}
              className={`flex flex-col items-start justify-start space-y-5 lg:w-[25%] w-[90%] rounded-lg xl:p-8 lg:p-6 p-[8vw] mx-2 mt-6 ${
                (index >= startIndex &&
                  index < startIndex + (showSingleCard ? 1 : 6)) ||
                (index === startIndex && showSingleCard)
                  ? "block"
                  : "hidden"
              } bg-gradient-to-r from-gray-900 to-gray-700 border-solid border border-[#424245] rounded-[30px]   mx-[2vw] md:mx-2 w-[352px] h-[282px] `}
            >
              <div className="flex items-center gap-2">
                {card.image && (
                  <img src={urlFor(card.image)} alt="/" className="w-8 h-8" />
                )}
                {card.imageTitle && (
                  <p className="poppins text-slate-50 text-lg  lg:text-[18px] font-medium">
                    {card.imageTitle}
                  </p>
                )}
              </div>
              <p className="nunito text-2xl font-semibold uppercase text-slate-200">
                {card.title}
              </p>
              {card.description && (
                <p className="notoSans text-slate-300 text-base">
                  {card.description.length > 60
                    ? `${card.description.slice(0, 60)}...`
                    : card.description}
                </p>
              )}
            </div>
          ))}
        </div>

        <div
          id="upcomingGames"
          className={`${
            activeSection === "upcomingGames" ? "block" : "hidden"
          } upcoming-games flex flex-wrap items-center justify-center gap-[2%] px-[2vw]`}
        >
          {upcomingGamesCards.map((card, index) => (
            <div
              key={index}
              className={`flex flex-col items-start space-y-4 lg:space-y-8 lg:w-[26%] w-[90%] rounded-xl xl:p-4 lg:p-6 p-[4vw] mx-2 mt-6 ${
                (index >= startIndex &&
                  index < startIndex + (showSingleCard ? 1 : 6)) ||
                (index === startIndex && showSingleCard)
                  ? "block"
                  : "hidden"
              } 
              
              2xl:w-[449px] 2xl:h-[557px] xl:w-[375px]  flex-shrink-0 rounded-lg border border-black shadow-md backdrop-blur 
              `}
              style={{background: "linear-gradient(88deg, #F6AF21 -13.21%, #FEF417 148.7%)"}}
            >
              {card.cardImage && (
                <img
                  src={urlFor(card.cardImage)}
                  alt="/"
                  className="w-full h-52 lg:h-[15vw] rounded-lg shadow-lg"
                />
              )}
              <p className="poppins text-black font-bold text-lg lg:text-2xl uppercase ">
                {card.cardTitle}
              </p>
              <p className="text-base font-bold lg:text-xl xl:text-2xl poppins text-gray-900 capitalize mt-5 mb-2">
                {card.platformTitle}
              </p>
              <div className="flex items-center justify-between w-full lg:pb-8">
                {card.platformIcon?.map((image, idx) => (
                  <Link key={idx} to={image.iconUrl} target="_blank">
                    <img
                      src={urlFor(image.icon)}
                      alt="icon"
                      className="w-8 h-8 mr-2 mt-4"
                    />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardsGames;
