import React, { useState, useEffect } from "react";
import client from "../../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

function OurServices() {
  const [servicesData, setServicesData] = useState(null);
  const [showDetails, setShowDetails] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    client
      .fetch('*[_type == "services"][0]')
      .then((data) => {
        setServicesData(data);
        setShowDetails(Array(data.expandText.length).fill(false));
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered && servicesData) {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % servicesData.crouselCards.length
        );
      }
    }, 6000);

    return () => clearInterval(interval);
  }, [isHovered, servicesData]);

  if (!servicesData) {
    return <div></div>; // or any loading indicator
  }

  const { title, subTitle, description, expandText, crouselCards } =
    servicesData;

  const toggleDetailVisibility = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="our-services lg:py-8 py-4" id="whatWeDo">
      <div className="our-services-heading flex flex-col items-center justify-center lg:space-y-8 md:space-y-6 space-y-[4vw] lg:py-8 py-10 px-10 lg:px-0 pb-0  text-center">
        <p className="monstrrate text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-xl  text-yellow-600 font-semibold uppercase leading-3 xl:leading-4">
          {title}
        </p>
        <h2 className="monstrrate text-[4vw] md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[56px] leading-6 md:leading-6 lg:leading-8 xl:leading-10 2xl:leading-[64px] tracking-wider font-bold uppercase" style={{color:'#2D2D2D'}}>
          {subTitle}
        </h2>
        <p className="poppins text-[3.8vw] md:text-2xl xl:[3xl] hidden md:block w-[50vw] text-gray-600 text-center font-normal">
          {description}
        </p>
        <p className="monstrrate text-sm md:text-2xl block md:hidden  text-gray-600 text-center font-normal">
          {description}
        </p>
      </div>
      <div className="our-services-cards w-full lg:px-16 lg:py-5 px-[5vw] py-4  flex flex-col-reverse md:flex-row items-center gap-[4%]">
        <div className="left-cards md:w-[45vw] w-full flex-col items-center space-y-6 lg:space-y-10">
          {expandText.map((text, index) => (
            <div
              key={index}
              className="left-card p-[4vw] md:p-[3.5vw] lg:px-[2vw] lg:py-[1.4vw] rounded-[1vw] relative"
              style={{ background: "#383840" }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-6 ">
                  <p className="monstrrate lg:text-[2vw] md:text-xl text-[5vw] font-semibold text-slate-100">
                    {text.expandTextTitle}
                  </p>
                  {text.expandTextTitleIcon && (
                    <img
                      className="w-[5vw] lg:w-[2.5vw]"
                      src={urlFor(text.expandTextTitleIcon)}
                      alt={text.expandTextTitleIcon.alt}
                    />
                  )}
                </div>
                <div
                  className="expand-btn bg-yellow-500 rounded-full w-[10vw] h-[10vw] md:w-[4vw] md:h-[4vw]  flex items-center justify-center cursor-pointer"
                  onClick={() => toggleDetailVisibility(index)}
                  style={{
                    transform: showDetails[index]
                      ? "rotate(90deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  {text.expandIcon && (
                    <img
                      className="w-[2vw] lg:w-[1vw]"
                      src={urlFor(text.expandIcon)}
                      alt={text.expandIcon.alt}
                    />
                  )}
                </div>
              </div>
              {showDetails[index] && (
                <div className="left-card-detail-text poppins text-slate-200 lg:pr-[8vw] lg:text-[1vw] xl:text-xl 2xl:text-2xl md:text-[2vw] text-base font-normal mt-4">
                  {text.expandTextDescription}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Carousel */}
        <div
          className="right-card-crousel bg-yellow-400 rounded-lg lg:p-4 my-8 lg:m-10 overflow-hidden w-full md:w-1/2"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="crousel-container top-0 left-0 w-full h-full flex relative">
            {crouselCards.map((image, index) => (
              <div
                key={index}
                className={`crousel-card-image w-full h-full transition-opacity duration-1000 ${
                  index === currentIndex ? "" : "opacity-0 absolute"
                }`}
              >
                {image.crouselCardImage && (
                  <img
                    className="p-6 w-[90vw] h-[40vh] md:h-[48vh] lg:w-[49vw] lg:h-[30vw] rounded-[12vw]  lg:rounded-[2vw]"
                    style={{
                      strokeWidth: "1.411px",
                      stroke: "#424245",
                      filter:
                        "drop-shadow(0px 12.703px 38.109px rgba(0, 0, 0, 0.25))",
                    }}
                    src={urlFor(image.crouselCardImage)}
                    alt={`crousel-card-${index}`}
                    // style={imagePosition}
                  />
                )}
              </div>
            ))}
            <div className="absolute bottom-2 left-[45%] lg:bottom-0 lg:left-[50%]  flex space-x-2">
              {[...Array(crouselCards.length)].map((_, dotIndex) => (
                <div
                  key={dotIndex}
                  className={`w-2 h-2 rounded cursor-pointer ${
                    dotIndex === currentIndex ? "bg-gray-700" : "bg-slate-100"
                  } ${dotIndex === currentIndex ? "w-2 h-2" : ""}`}
                  onClick={() => handleDotClick(dotIndex)} // Call handleDotClick function on dot click
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
