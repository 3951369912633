import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";
import client from "../../sanityCli"; // Update the path according to your project structure
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function Error() {
  const [errorPageData, setErrorPageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`*[_type == "errorPage"][0]`);
        setErrorPageData(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchData();
  }, []);

  if (!errorPageData) return <div></div>;

  return (
    <>
      <Navbar />

      <div className="w-full flex flex-col items-center justify-center space-y-8 my-20 px-4">
        <img
          src={urlFor(errorPageData.image)}
          alt="error"
          className="w-[50vw] max-w-[600px]"
        />
        <p className="text-black font-semibold text-2xl md:text-4xl lg:text-5xl 2xl:text-7xl text-center">
          {errorPageData.title}
        </p>
        <p className="text-gray-500 text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-normal text-center">
          {errorPageData.description}
        </p>
        <div className="flex flex-wrap items-center gap-4 py-8">
          {errorPageData.buttons.map((button, index) => (
            <Link
              key={index}
              to={button.url}
              className="w-fit px-4 text-center hover:bg-yellow-500 hover:p-4 hover:rounded-xl hover:text-black text-lg lg:text-2xl text-yellow-600 py-2 border border-yellow-600 rounded-lg"
            >
              {button.text}
            </Link>
          ))}
        </div>
        <p className="text-gray-700 text-xl">{errorPageData.followTitle}</p>
        <div className="flex items-center justify-center space-x-8">
          {errorPageData.socialLinks.map((social, index) => (
            <Link
              key={index}
              to={social.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ borderRadius: "29.945px", opacity: "0.6", background:'#F8C11E'
            }}
            className="p-2 h-[20vw] w-[20vw] md:w-20 md-h-20 lg:w-20 lg:h-20"
            >
              <img
                src={urlFor(social.icon)}
                alt="/"
               
              />
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Error;
