import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function Blog() {
  const [blogData, setBlogData] = useState(null); // State to hold fetched blog data
  const [pageData, setPageData] = useState(null);
  const [activeType, setActiveType] = useState("All"); // Track active type
  const [randomBlog, setRandomBlog] = useState(null); // Initialize randomBlog state
  const [blogsToShow, setBlogsToShow] = useState(9); // Number of blogs to display initially
  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility
  const [isMobile, setIsMobile] = useState(false); // State to manage mobile view

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`
          *[_type == 'blogs'][0]{
            ...
          }
        `);
        setBlogData(data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchPageData();
    fetchData(); // Fetch blog data on component mount
  }, []);

  useEffect(() => {
    if (blogData && blogData.blogData.length > 0) {
      const randomBlogIndex = Math.floor(Math.random() * blogData.blogData.length);
      setRandomBlog(blogData.blogData[randomBlogIndex]);
    }
  }, [blogData]);

  const handleResize = () => {
    // Update number of blogs to show based on screen size
    if (window.innerWidth < 768) {
      setBlogsToShow(3);
      setIsMobile(true);
    } else {
      setBlogsToShow(9);
      setIsMobile(false);
    }
  };

  useEffect(() => {
    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Call handleResize initially
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filterBlogs = (type) => {
    setActiveType(type); // Set active type
  };

  const handleLoadMore = () => {
    const increment = isMobile ? 3 : 9;
    const filteredBlogs = blogData.blogData.filter(
      (blog) => activeType === "All" || blog.type === activeType
    );

    if (filteredBlogs.length <= blogsToShow) {
      setShowAlert(true); // Show alert if no more blogs to load
      setTimeout(() => setShowAlert(false), 3000); // Hide alert after 3 seconds
    } else {
      setBlogsToShow((prev) => prev + increment); // Increase the number of blogs to show
    }
  };

  if (!blogData) return <div></div>;

  return (
    <>
      <div className="blog-container px-[5%]">
        {/* Hero section */}
        {randomBlog && (
          <div className="blog-hero-page w-full flex md:flex-row flex-col items-center md:space-x-20 py-[5%]">
            <div className="blog-hero-left w-full md:px-0 md:w-[45vw] md:h-[22vw]">
             {randomBlog.blogImage && <img
                src={urlFor(randomBlog.blogImage)}
                alt={randomBlog.type}
                className="h-[14rem] md:w-[45vw] md:h-[24vw]"
              />}
            </div>
            <div className="blog-hero-right w-full md:w-[55vw] flex flex-col items-start gap-4 py-8">
              <p className="monstrrate text-[16px] md:text-xl font-semibold text-[#707070]">
                {randomBlog.blogAuthor}
              </p>
              <p className="poppins text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-semibold  uppercase text-[#000000]">
                {randomBlog.blogHeader}
              </p>
              <span className="monstrrate text-base text-[#707070] font-normal flex items-center gap-2">
               <span>{randomBlog.blogReleaseDate}</span>
               <span className="w-1 h-1 rounded-full bg-[#2d2d2d]"></span>
              <span> {randomBlog.blogReleaseTime}</span>
              </span>
            </div>
          </div>
        )}

        {/* Filter links */}
        <div className="filter-link flex md:flex-row flex-col items-start md:items-center justify-between md:px-0 lg:mt-12">
          <h1 className="monstrrate text-3xl lg:text-5xl xl:text-6xl 2xl:text-[80px] 2x:leading-[120px] text-[#1E1E1E] font-bold md:w-2/5">
            {blogData.latestPost}
          </h1>
          <style jsx>{`
            .hide-scrollbar {
              /* For WebKit browsers */
              -webkit-overflow-scrolling: touch;
              scrollbar-width: none; /* Firefox */
            }

            .hide-scrollbar::-webkit-scrollbar {
              display: none; /* Safari and Chrome */
            }
          `}</style>
          <ul className="md:w-3/5 flex items-center lg:justify-around lg:gap-2 gap-[10%] w-full mt-8 md:mt-0 overflow-x-auto hide-scrollbar">
            {blogData.blogType.map((type, id) => (
              <li
                key={id}
                onClick={() => filterBlogs(type.type)}
                className={`poppins border-b-2 text-lg lg:text-xl 2xl:text-2xl text-center font-bold pb-2 hover:cursor-pointer whitespace-nowrap ${
                  activeType === type.type
                    ? "text-[#1E1E1E] border-[#1E1E1E]"
                    : "border-[#707070] text-[#707070]"
                }`}
              >
                {type.type}
              </li>
            ))}
          </ul>
        </div>

        {/* Render filtered blogs */}
        <div className="blog-separate-box-container flex flex-wrap items-start justify-center gap-[4vw] my-[5%]">
          {pageData &&
            blogData.blogData
              .filter((blog) => activeType === "All" || blog.type === activeType)
              .slice(0, blogsToShow)
              .map((blog) => (
                <Link
                  to={`/${pageData.blog ? pageData.blog : 'blog'}/${blog.path}`}
                  key={blog.id}
                  className="w-full md:w-[30%] flex flex-col items-start space-y-4 my-6"
                >
                  <div className="w-full">
                   {blog.blogImage && <img
                      src={urlFor(blog.blogImage)}
                      alt={blog.type}
                      className="w-full h-[14rem] md:h-[15rem] lg:h-[16rem] 2xl:h-[291px]"
                    />}
                  </div>
                  <div className="flex flex-col items-start space-y-4">
                    <p className="monstrrate text-[10px] xl:text-base font-semibold uppercase text-[#707070]">
                      {blog.blogAuthor}
                    </p>
                    <p className="poppins text-[18px] lg:text-xl xl:text-2xl font-semibold text-[#000000] uppercase">
                      {blog.blogHeader}
                    </p>
                    <span className="monstrrate text-[10px] xl:text-base font-semibold uppercase text-[#707070] flex items-center gap-2">
                     <span>{blog.blogReleaseDate} </span>
                     <span className="w-1 h-1 rounded-full bg-[#707070]"></span>
                      <span>{blog.blogReleaseTime}</span>
                    </span>
                  </div>
                </Link>
              ))}
        </div>

        {/* Show More button */}
        <button
          className="monstrrate bg-gradient-to-r from-yellow-400 to-yellow-500  mx-auto font-semibold text-[#2d2d2d] flex items-center justify-center p-4 mb-[10%]  text-base lg:text-xl xl:text-[22px] rounded-xl capitalize"
          onClick={handleLoadMore}
        >
          {blogData.showMoreBtn || "Show More"}
        </button>

        {/* Alert message */}
        {showAlert && (
         <div className="alert-message fixed top-[0vh] left-[40%] poppins transform-translate-x-1/2 bg-gradient-to-r from-yellow-500 to-yellow-300 text-3xl text-[#ffffff] py-4 px-8 rounded">
            {blogData.alertMsg || "There are no more blogs to show 😑"}
          </div>
        )}
      </div>
    </>
  );
}

export default Blog;
