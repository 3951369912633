import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../sanityCli";
import Footer from "../Home/homeSec9/Footer";
import Navbar from "../navbar/Navbar";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function CookiesPolicy() {
  const [cookiesPolicy, setCookiesPolicy] = useState(null);

  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "CookiesPolicy"][0]');
        setCookiesPolicy(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!cookiesPolicy) {
    return <div></div>;
  }

  const {
    privacyPromiss,
    privacyPolicyTitle,
    privacyPolicyDescription,
    effectiveData,
    privacyPolicyWebsite,
    generalProvisionTitle,
    generalProvisionDescription,
    otherProvisionTitle,
    otherProvisionDescription,
    images: { firstImg, secondImg, thirdImg },
  } = cookiesPolicy;

  return (
    <>
    <Navbar />
    <div className="w-full lg:px-40 px-8 pt-4 lg:pt-0 pb-[10rem] flex items-start lg:gap-40">
      <div className="md:w-3/5 w-full">
        <div className="flex flex-col gap-6 md:my-16 my-4">
        <p className="poppins capitalize lg:text-base text-sm font-medium text-[#707070]">{privacyPromiss}</p>
        <p className="monstrrate text-3xl xl:text-4xl 2xl:text-[4rem] 2xl:leading-[6rem] text-[#1e1e1e] font-semibold">
            {privacyPolicyTitle}
          </p>
          <p className="poppins lg:text-base text-sm  2xl:text-2xl font-normal text-[#4E4E4E]-800">{privacyPolicyDescription}</p>
          <p className="flex flex-col lg:flex-row  items-start lg:items-center gap-4 lg:gap-8">
              <span className="text-base lg:text-xl  2xl:text-2xl capitalize text-[#000000]">{effectiveData}</span>
              <span className="text-base lg:text-xl  2xl:text-2xl capitalize text-[#000000]">
                {privacyPolicyWebsite}
              </span>
            </p>
        </div>
        <div className="flex flex-col  mt-[2rem] lg:mt-[10rem]">
        <p className="monstrrate text-3xl lg:text-4xl  font-semibold text-[#1E1E1E] capitalize lg:mb-[2rem]">{generalProvisionTitle}</p>
        {generalProvisionDescription.map((desc, index) => (
              <p className="poppins text-base lg:text-lg xl:text-xl 2xl:text-2xl font-normal text-[#4E4E4E] my-4" key={index}>{desc}</p>
            ))}
        </div>
        <div className="flex flex-col gap-4 mt-[2rem] lg:mt-20">
            <p className="monstrrate text-3xl lg:text-4xl  font-semibold text-[#1E1E1E] capitalize">{otherProvisionTitle}</p>
            {otherProvisionDescription.map((desc, index) => (
              <p className="poppins text-base lg:text-lg xl:text-xl 2xl:text-2xl font-normal text-[#4E4E4E] my-4" key={index}>{desc}</p>
            ))}
          </div>
      </div>
      <div className="md:w-1/5 md:block hidden">
        <img src={urlFor(firstImg)} alt="Pen" className="ml-6" />
        <img src={urlFor(secondImg)} alt="Line" className="w-40 -mt-2" />
        <img src={urlFor(thirdImg)} alt="Home" className="-mt-20 w-full" />
      </div>
    </div>
    <Footer />
    </>
  );
}
